import {default as CoreView} from '@clevermind/tinty2-webapp-core/model/View';
import {default as DropPoint} from '@clevermind/webapp-lariolibri/lib/resources/DropPoint';
import {map, uniq, chunk, filter, compact} from 'lodash';

export default class Droppoints extends CoreView {
  dropoints: DropPoint[];
  regionColumns: string[][];

  async __activate() {
    await super.__activate();
    this.dropoints = await this.getResource('drop_points_public_view', {is_active: true, order: {'region': 'ASC', 'business_name': 'ASC'}});
    const regions = uniq(map(this.dropoints, 'region'));
    const columns = 4;
    this.regionColumns = chunk(regions, columns);
  }

  getResionDropPoints(region:string) {
    return filter(this.dropoints, {region: region});
  }

  getDropPointAddress(dropoint: DropPoint) {
    return compact([dropoint.address, dropoint.postal_code, dropoint.city, dropoint.province]).join(' ');
  }
}