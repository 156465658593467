import {computedFrom} from 'aurelia-framework';
import {cloneDeep, find, isEqual, isUndefined} from 'lodash';
import { default as CoreSignUser } from '@clevermind/tinty2-webapp-bootstrap/views/users/signup';
import {default as Constants} from '../../lib/Constants';

export default class SignUp extends CoreSignUser {
  provinces = [];
  types = [{
    label: 'Customer.Type.StationeryBookshop',
    value: Constants._CUSTOMER_TYPE_STATIONERY_BOOKSHOP
  }, {
    label: 'Customer.Type.ScholasticBookshop',
    value: Constants._CUSTOMER_TYPE_SCHOLASTIC_BOOKSHOP
  }, {
    label: 'Customer.Type.Distributor',
    value: Constants._CUSTOMER_TYPE_SCHOLASTIC_DISTRIBUTOR
  }, {
    label: 'Customer.Type.NewsKiosk',
    value: Constants._CUSTOMER_TYPE_SCHOLASTIC_NEWS_KIOSK
  }, {
    label: 'Customer.Type.BookStore',
    value: Constants._CUSTOMER_TYPE_BOOKSTORE
  }, {
    label: 'Customer.Type.Other',
    value: Constants._CUSTOMER_TYPE_OTHER
  }];
  enableAdministrativeOffice = false;
  privacy_accepted = false;
  signUpData = {
    business_name: '',
    enable_newsletter: false,
    type: '',
    referent: '',
    fiscal_code: '',
    vat_number: '',
    sdi: '',
    iban: '',
    landline_phone: '',
    challenge_redirect: '',
    fax: '',
    email: '',
    pec: '',
    addresses: [{
      province: null,
      city: null,
      address: '',
      postal_code: '',
      type: Constants._ADDRESS_TYPE_REGISTERED_OFFICE
    }, {
      province: null,
      city: null,
      address: '',
      postal_code: '',
      type: Constants._ADDRESS_TYPE_ADMINISTRATIVE_OFFICE
    }]
  };

  @computedFrom('signUpData', 'signUpData.addresses', 'signUpData.addresses[0].province')
  get registeredOfficeCityFilter() {
    if (this.signUpData?.addresses[0]?.province) {
      const province = find(this.provinces, {abbreviation: this.signUpData.addresses[0].province});
      return {province: province.code};
    }
    return {};
  }

  async registeredOfficeProvinceChanged(newValue, oldValue) {
    if (!isEqual(newValue, oldValue) && !isUndefined(oldValue)) {
      this.signUpData.addresses[0].city = null;
    }
  }

  @computedFrom('signUpData', 'signUpData.addresses', 'signUpData.addresses[1].province')
  get administrativeOfficeCityFilter() {
    if (this.signUpData?.addresses[1]?.province) {
      const province = find(this.provinces, {abbreviation: this.signUpData.addresses[1].province});
      return {province: province.code};
    }
    return {};
  }

  async __activate(params: { cancel_url?: string; redirect_uri?: string; state?: string; }): Promise<void> {
    this.provinces = await this.getResource('provinces');
    return super.__activate(params);
  }

  async administrativeOfficeProvinceChanged(newValue, oldValue) {
    if (!isEqual(newValue, oldValue) && !isUndefined(oldValue)) {
      this.signUpData.addresses[1].city = null;
    }
  }

  async signUp() {
    await this.isValid();
    const signupdata = cloneDeep(this.signUpData);
    if (!this.enableAdministrativeOffice) {
      signupdata.addresses.splice(1, 1);
    }
    signupdata.challenge_redirect = 'reserved-area';
    await this.postAPI('shop/register', signupdata, {backend: 'auth', family: 'oauth'});
    await this.showModal({type: 'success', title: 'Page.SignUp.RegistrationConfirmed', body: 'Page.SignUp.RegisteredInstructions'});
    this.navigate('#/');
  }
}