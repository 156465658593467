import {default as CoreView} from '@clevermind/tinty2-webapp-core/model/View';

export default class SignUpCustomer extends CoreView {
  salePoint = null;
  user = {name: '', surname: '', email: '', mobile_phone: '', token: '', challenge_redirect: ''};
  privacy_accepted = false;
  
  async __activate(params: {token?: string}, ...aArgs: any[]): Promise<any> {
    if (params?.token) {
      try {
        this.salePoint = await this.postAPI('validate-registration-token', {token: params.token}, {redirectToErrorPage: false});
        this.user.token = params.token;
      } catch(_error) {
        // error handled in interface
      }
    }
  }

  async signUp() {
    await this.isValid();
    this.showConfirm({
      operation: async () => {
        this.user.challenge_redirect = 'reserved-area';
        await this.postAPI('shop-customer', this.user, {redirectToErrorPage: false});
        await this.showModal({icon: 'fad fa-mailbox text-primary', title: 'Page.SignUser.CheckMailBox', body: 'Page.SignUser.RegisteredInstructions'});
        this.navigate('#/');
      }
    });
  }

}