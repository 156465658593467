import {customElement} from 'aurelia-framework';
// import {default as CoreFooter} from '@clevermind/tinty2-webapp-bootstrap/components/layout/footer';
import {default as CoreView} from '@clevermind/tinty2-webapp-core/model/View';
import * as moment from 'moment';

@customElement('lario-footerbar')
export default class LarioFooterbar extends CoreView {
  email = null;
  termsPrivacy = false;
  copyright: string = moment().format('YYYY');
  // Constants: any = Constants;

  async subscribeToNewsletter() {
    await this.isValid();
    this.showConfirm({
      operation: async () => {
        await this.postAPI('subscribe-newsletter', {email: this.email}, {redirectToErrorPage: false});
        this.showModal({
          title: 'Components.Footer.SubscribedToNewsletter.Title',
          body: 'Components.Footer.SubscribedToNewsletter.Body'
        })
      }
    })
  }
}
