import { PLATFORM } from 'aurelia-pal';
import { Router, RouterConfiguration, Redirect } from 'aurelia-router';
import {AuthorizeStep} from '@clevermind/tinty2-webapp-core/views/app';
import { default as AppLarioLibri } from '@clevermind/webapp-lariolibri/app';
import { default as Locales } from './locales';
import {default as Constants} from './lib/Constants';
import {map, filter} from 'lodash';
// import the main scss like this, in order to prevent the page being rendered before the styles are applied!
import './app.scss';

export class App extends AppLarioLibri {
  defaultSignInRoute = 'reserved-area';
  constructor(...aArgs: any[]) {
    super(...aArgs);
    this.root = 'lariolibri';
    this.locales = Locales;
    const _authorizationStep: any = LarioLibriStep;
    this.authorizationSteps = [_authorizationStep]; // Init authorization steps
    this.verticalization = 'lariolibri';
  }

  public configureRouter(config: RouterConfiguration, router: Router) {
    super.configureRouter(config, router);
    config.options.pushState = true;
    config.options.root = '/';
    // override the layout
    config.map([{
      route: ['', 'homepage'],
      name: 'homepage',
      moduleId: PLATFORM.moduleName('lariolibri/views/homepage'),
      nav: false,
      title: 'Page.Homepage.Label',
      disableAuth: true,
      layoutViewModel: PLATFORM.moduleName('lariolibri/layout/website')
    }, {
      route: ['about-us'],
      name: 'about-us',
      moduleId: PLATFORM.moduleName('lariolibri/views/about-us'),
      nav: false,
      title: 'Page.AboutUs.Label',
      disableAuth: true,
      layoutViewModel: PLATFORM.moduleName('lariolibri/layout/website')
    }, {
      route: ['services-reseller'],
      name: 'services-reseller',
      moduleId: PLATFORM.moduleName('lariolibri/views/services-reseller'),
      nav: false,
      title: 'Page.Services.Reseller.Label',
      disableAuth: true,
      layoutViewModel: PLATFORM.moduleName('lariolibri/layout/website')
    }, {
      route: ['services-schools'],
      name: 'services-schools',
      moduleId: PLATFORM.moduleName('lariolibri/views/services-school'),
      nav: false,
      title: 'Page.Services.Schools.Label',
      disableAuth: true,
      layoutViewModel: PLATFORM.moduleName('lariolibri/layout/website')
    }, {
      route: ['active-droppoints'],
      name: 'active-droppoints',
      moduleId: PLATFORM.moduleName('lariolibri/views/droppoints'),
      nav: false,
      title: 'Page.DropPoints.Label',
      disableAuth: true,
      layoutViewModel: PLATFORM.moduleName('lariolibri/layout/website')
    }, {
      route: ['contacts'],
      name: 'contacts',
      moduleId: PLATFORM.moduleName('lariolibri/views/contacts'),
      nav: false,
      title: 'Page.Contacts.Label',
      disableAuth: true,
      layoutViewModel: PLATFORM.moduleName('lariolibri/layout/website')
    }, {
      route: ['reserved-area'],
      name: 'reserved-area',
      moduleId: PLATFORM.moduleName('lariolibri/views/reserved-area/reserved-area'),
      nav: true,
      useStrictUserTypes: true,
      userRoles: map(filter(this.getUserRoles(), (_role) => _role.mainRole !== Constants._USER_ROLE_DROPPOINT_RESPONSIBLE && _role.mainRole !== Constants._USER_ROLE_COMPANY_WAREHOUSE_OPERATOR), 'value'),
      title: 'Page.ReservedArea.Label',
      icon: 'fal fa-home',
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')
    }, {
      route: ['company-profile'],
      name: 'company-profile',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/company-profile'),
      nav: true,
      title: 'Page.CompanyProfile.Label',
      icon: 'fal fa-user-check',
      useStrictUserTypes: true,
      userRoles: [Constants._USER_ROLE_SHOP_RESPONSIBLE, Constants._USER_ROLE_USER],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')
    }, {
      route: ['products/:filter?'],
      name: 'products',
      href: 'products',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/search-products/products'),
      nav: true,
      title: 'Page.Products.Label',
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.SearchProducts',
        icon: 'fal fa-search'
      },
      useStrictUserTypes: true,
      userRoles: map(filter(this.getUserRoles(), (_role) => _role.mainRole !== Constants._USER_ROLE_DROPPOINT_RESPONSIBLE && _role.mainRole !== Constants._USER_ROLE_COMPANY_WAREHOUSE_OPERATOR), 'value')
    }, {
      route: ['adoptions'],
      name: 'adoptions',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/search-products/adoptions'),
      nav: true,
      title: 'Page.Adoptions.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR, Constants._USER_ROLE_SHOP_RESPONSIBLE],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.SearchProducts',
        icon: 'fal fa-search'
      }
    }, {
      route: ['admin-drop-point-receiving'],
      name: 'admin-drop-point-receiving',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/drop-point/receiving'),
      nav: true,
      title: 'Page.DropPointReceiving.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.DropPoint',
        icon: 'fal fa-list-dropdown'
      }
    }, {
      route: ['admin-drop-point-deliveries'],
      name: 'admin-drop-point-deliveries',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/drop-point/deliveries'),
      nav: true,
      icon: 'fal fa-person-carry-box',
      title: 'Page.DropPointDeliveries.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.DropPoint',
        icon: 'fal fa-list-dropdown'
      }
    }, { // Same views as above, but in the main menu for the drop point responsible
      route: ['drop-point-receiving'],
      name: 'drop-point-receiving',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/drop-point/receiving'),
      nav: true,
      title: 'Page.DropPointReceiving.Label',
      useStrictUserTypes: true,
      icon: 'fal fa-hand-holding-box',
      userRoles: [Constants._USER_ROLE_DROPPOINT_RESPONSIBLE, Constants._USER_ROLE_USER],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')
    }, {
      route: ['drop-point-deliveries'],
      name: 'drop-point-deliveries',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/drop-point/deliveries'),
      nav: true,
      icon: 'fal fa-person-carry-box',
      title: 'Page.DropPointDeliveries.Label',
      useStrictUserTypes: true,
      userRoles: [Constants._USER_ROLE_DROPPOINT_RESPONSIBLE, Constants._USER_ROLE_USER],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')
    }, {
      route: ['return-lists-droppoint'],
      name: 'return-lists-droppoint',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/drop-point/return-lists'),
      nav: true,
      icon: 'fal fa-turn-down-left',
      title: 'Page.ReturnLists.Label',
      useStrictUserTypes: true,
      userRoles: [Constants._USER_ROLE_DROPPOINT_RESPONSIBLE, Constants._USER_ROLE_USER],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
    },  {
      route: ['form-return-lists-droppoint/:id'],
      name: 'form-return-lists-droppoint',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/drop-point/form-return-lists'),
      nav: false,
      icon: 'fal fa-turn-down-left',
      title: 'Page.ReturnLists.Label',
      useStrictUserTypes: true,
      userRoles: [Constants._USER_ROLE_DROPPOINT_RESPONSIBLE, Constants._USER_ROLE_USER],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')
    }, {
      route: ['gdo'],
      name: 'gdo',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/administrators/gdo'),
      nav: true,
      icon: 'fal fa-store',
      title: 'Page.GDO.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.GDO.Label',
        icon: 'fal fa-store'
      }
    }, {
      route: ['shops/gdo'],
      name: 'shops-gdo',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/operators/destinations'),
      nav: true,
      icon: 'fal fa-location-dot',
      title: 'Page.ShopsGDO.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.GDO.Label',
        icon: 'fal fa-store'
      }
    }, {
      route: ['notifications-gdo'],
      name: 'notifications-gdo',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/operators/notifications'),
      nav: true,
      icon: 'fal fa-comments',
      title: 'Page.NotificationsGDO.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.GDO.Label',
        icon: 'fal fa-store'
      }
    }, {
      route: ['customers-gdo/:disabled?'],
      name: 'customers-gdo',
      href: 'customers-gdo',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/customers'),
      nav: true,
      icon: 'fal fa-users',
      title: 'Page.CustomersGDO.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.GDO.Label',
        icon: 'fal fa-store'
      }
    }, {
      route: ['orders-gdo'],
      name: 'orders-gdo',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/orders/orders'),
      nav: true,
      icon: 'fal fa-shopping-cart',
      title: 'Page.OrdersGDO.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.GDO.Label',
        icon: 'fal fa-store'
      }
    }, {
      route: ['operators-orders-gdo'],
      name: 'operators-orders-gdo',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/operators/pre-orders'),
      nav: true,
      icon: 'fal fa-shopping-cart',
      title: 'Page.PreOrdersGDO.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.GDO.Label',
        icon: 'fal fa-store'
      }
    }, {
      route: ['cancel-requests-gdo'],
      name: 'cancel-requests-gdo',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/cancel-requests'),
      nav: true,
      title: 'Page.CancelRequestsGDO.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.GDO.Label',
        icon: 'fal fa-store'
      }
    }, {
      route: ['receiving-gdo'],
      name: 'receiving-gdo',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/documents/receiving'),
      nav: true,
      icon: 'fal fa-hand-holding-box',
      title: 'Page.ReceivingGDO.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.GDO.Label',
        icon: 'fal fa-store'
      }
    }, {
      route: ['deliveries-gdo'],
      name: 'deliveries-gdo',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/documents/deliveries'),
      nav: true,
      icon: 'fal fa-person-carry-box',
      title: 'Page.DeliveriesGDO.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.GDO.Label',
        icon: 'fal fa-store'
      }
    }, {
      route: ['return-lists-gdo'],
      name: 'return-lists-gdo',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/documents/return-lists'),
      nav: true,
      icon: 'fal fa-turn-down-left',
      title: 'Page.ReturnListsGDO.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.GDO.Label',
        icon: 'fal fa-store'
      }
    }, {
      route: ['undeliverable-documents-gdo'],
      name: 'undeliverable-documents-gdo',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/operators/undeliverable-documents'),
      nav: true,
      icon: 'fal fa-turn-down-left',
      title: 'Page.UndeliverableDocumentsGDO.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.GDO.Label',
        icon: 'fal fa-store'
      }
    }, {
      route: ['shops'],
      name: 'shops',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/operators/shops'),
      nav: true,
      icon: 'fal fa-shop',
      title: 'Page.Shops.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Shop.Label',
        icon: 'fal fa-shop'
      }
    }, {
      route: ['customers-shop/:disabled?'],
      name: 'customers-shop',
      href: 'customers-shop',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/customers'),
      nav: true,
      icon: 'fal fa-users',
      title: 'Page.ShopsCustomers.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Shop.Label',
        icon: 'fal fa-shop'
      }
    }, {
      route: ['orders-shops'],
      name: 'orders-shops',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/orders/orders'),
      nav: true,
      icon: 'fal fa-shopping-cart',
      title: 'Page.OrdersShop.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Shop.Label',
        icon: 'fal fa-shop'
      }
    }, {
      route: ['operators-orders'],
      name: 'operators-orders',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/operators/pre-orders'),
      nav: true,
      icon: 'fal fa-shopping-cart',
      title: 'Page.PreOrders.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Shop.Label',
        icon: 'fal fa-shop'
      }
    }, {
      route: ['attestations-shops'],
      name: 'attestations-shops',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/attestations'),
      nav: true,
      icon: 'fal fa-download',
      title: 'Page.Attestations.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Shop.Label',
        icon: 'fal fa-shop'
      }
    }, {
      route: ['new-shipping-request-shops'],
      name: 'new-shipping-request-shops',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/new-shipping-request'),
      nav: true,
      icon: 'fal fa-truck',
      title: 'Page.NewShippingRequest.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Shop.Label',
        icon: 'fal fa-store'
      }
    }, {
      route: ['cancel-requests-shops'],
      name: 'cancel-requests-shops',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/cancel-requests'),
      nav: true,
      icon: 'fal fa-ban',
      title: 'Page.CancelRequests.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Shop.Label',
        icon: 'fal fa-shop'
      }
    }, {
      route: ['receiving-shops'],
      name: 'receiving-shops',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/documents/receiving'),
      nav: true,
      icon: 'fal fa-hand-holding-box',
      title: 'Page.ReceivingShops.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Shop.Label',
        icon: 'fal fa-store'
      }
    }, {
      route: ['deliveries-shops'],
      name: 'deliveries-shops',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/documents/deliveries'),
      nav: true,
      icon: 'fal fa-person-carry-box',
      title: 'Page.DeliveriesShops.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Shop.Label',
        icon: 'fal fa-store'
      }
    }, {
      route: ['return-lists-shops'],
      name: 'return-lists-shops',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/documents/return-lists'),
      nav: true,
      icon: 'fal fa-turn-down-left',
      title: 'Page.ReturnListsShops.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Shop.Label',
        icon: 'fal fa-store'
      }
    }, {
      route: ['undeliverable-documents-shops'],
      name: 'undeliverable-documents-shops',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/operators/undeliverable-documents'),
      nav: true,
      icon: 'fal fa-turn-down-left',
      title: 'Page.UndeliverableDocuments.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Shop.Label',
        icon: 'fal fa-store'
      }
    }, {
      route: ['warehouse-orders'],
      name: 'warehouse-orders',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/operators/pre-orders'),
      nav: true,
      icon: 'fal fa-shopping-cart',
      title: 'Page.WarehouseOrders.Label',
      useStrictUserTypes: true,
      userRoles: [Constants._USER_ROLE_COMPANY_WAREHOUSE_OPERATOR, Constants._USER_ROLE_USER],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')
    }, {
      route: ['form-operators-order/:id'],
      name: 'form-operators-order',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/operators/form-pre-order'),
      nav: false,
      title: 'Page.FormPreOrder.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR, Constants._USER_ROLE_COMPANY_WAREHOUSE_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')
    }, {
      route: ['customers/:disabled?'],
      name: 'customers',
      href: 'customers',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/customers'),
      nav: true,
      icon: 'fal fa-users',
      title: 'Page.Customers.Label',
      useStrictUserTypes: true,
      additionalFilter: () => {
        return this.activeDestination?.enable_user_registration ? true : false
      },
      userRoles: [Constants._USER_ROLE_SHOP_RESPONSIBLE, Constants._USER_ROLE_USER],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')
    }, {
      route: ['cart'],
      name: 'cart',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/orders/cart'),
      nav: true,
      icon: 'fal fa-shopping-cart',
      title: 'Page.Cart.Label',
      useStrictUserTypes: true,
      userRoles: [
        [Constants._USER_ROLE_SHOP_RESPONSIBLE, Constants._USER_ROLE_USER],
        [Constants._USER_ROLE_USER]
      ],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Orders',
        icon: 'fal fa-shopping-cart'
      }
    }, {
      route: ['orders'],
      name: 'orders',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/orders/orders'),
      nav: true,
      icon: 'fal fa-shopping-cart',
      title: 'Page.Orders.Label',
      useStrictUserTypes: true,
      userRoles: [
        [Constants._USER_ROLE_SHOP_RESPONSIBLE, Constants._USER_ROLE_USER],
      ],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Orders',
        icon: 'fal fa-shopping-cart'
      }
    }, {
      route: ['new-shipping-request'],
      name: 'new-shipping-request',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/new-shipping-request'),
      nav: true,
      icon: 'fal fa-truck',
      title: 'Page.NewShippingRequest.Label',
      useStrictUserTypes: true,
      userRoles: [
        [Constants._USER_ROLE_SHOP_RESPONSIBLE, Constants._USER_ROLE_USER],
      ],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Orders',
        icon: 'fal fa-shopping-cart'
      }
    }, {
      route: ['shipping-requests-shops'],
      name: 'shipping-requests-shops',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/shipping-requests'),
      nav: true,
      icon: 'fal fa-truck',
      title: 'Page.ShippingRequests.Label',
      useStrictUserTypes: true,
      userRoles: [
        [Constants._USER_ROLE_SHOP_RESPONSIBLE, Constants._USER_ROLE_USER],
      ],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Orders',
        icon: 'fal fa-shopping-cart'
      }
    }, {
      route: ['orders-exploded/:customer_id?'],
      name: 'orders-exploded',
      href: 'orders-exploded',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/orders/orders-exploded'),
      nav: true,
      icon: 'fal fa-shopping-cart',
      title: 'Page.OrdersExploded.Label',
      useStrictUserTypes: true,
      userRoles: [
        [Constants._USER_ROLE_SHOP_RESPONSIBLE, Constants._USER_ROLE_USER],
        [Constants._USER_ROLE_USER]
      ],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Orders',
        icon: 'fal fa-shopping-cart'
      }
    }/*, {
      route: ['orders-report'],
      name: 'orders-report',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/orders/orders-report'),
      nav: true,
      icon: 'fal fa-shopping-cart',
      title: 'Page.OrdersReport.Label',
      useStrictUserTypes: true,
      userRoles: [
        [Constants._USER_ROLE_SHOP_RESPONSIBLE, Constants._USER_ROLE_USER]
      ],
      layoutModel: {
        useParentRouter: true
      },
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Orders',
        icon: 'fal fa-shopping-cart'
      }
    }*/, {
      route: ['order-detail/:id'],
      name: 'order-detail',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/orders/order-detail'),
      nav: false,
      title: 'Page.OrderDetails.Label',
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')
    }, {
      route: ['documents-templates'],
      name: 'documents-templates',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/administrators/documents-templates'),
      nav: true,
      icon: 'fal fa-file-word',
      title: 'Page.DocumentsTemplates.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')
    }, {
      route: ['files'],
      name: 'files',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/operators/files'),
      nav: true,
      icon: 'fal fa-files',
      title: 'Page.Files.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')
    }, {
      route: ['drop-points'],
      name: 'drop-points',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/operators/drop-points'),
      nav: true,
      icon: 'fal fa-truck-ramp-box',
      title: 'Page.DropPoints.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')
    }, {
      route: ['shipping-requests'],
      name: 'shipping-requests',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/shipping-requests'),
      nav: true,
      icon: 'fal fa-truck',
      title: 'Page.ShippingRequests.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')
    }/*, {
      route: ['receiving'],
      name: 'receiving',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/documents/receiving'),
      nav: true,
      icon: 'fal fa-hand-holding-box',
      title: 'Page.Receiving.Label',
      useStrictUserTypes: true,
      userRoles: [Constants._USER_ROLE_SHOP_RESPONSIBLE, Constants._USER_ROLE_USER],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Documents',
        icon: 'fal fa-hand-holding-box'
      }
    }, {
      route: ['deliveries'],
      name: 'deliveries',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/documents/deliveries'),
      nav: true,
      icon: 'fal fa-person-carry-box',
      title: 'Page.Deliveries.Label',
      useStrictUserTypes: true,
      userRoles: [Constants._USER_ROLE_SHOP_RESPONSIBLE, Constants._USER_ROLE_USER],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Documents',
        icon: 'fal fa-hand-holding-box'
      }
    }*/, {
      route: ['documents-export'],
      name: 'documents-export',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/documents/documents-export'),
      nav: true,
      icon: 'fal fa-file-excel',
      title: 'Page.DocumentsExport.Label',
      useStrictUserTypes: true,
      userRoles: [Constants._USER_ROLE_SHOP_RESPONSIBLE, Constants._USER_ROLE_USER],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Documents',
        icon: 'fal fa-hand-holding-box'
      }
    }, {
      route: ['return-lists'],
      name: 'return-lists',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/documents/return-lists'),
      nav: true,
      icon: 'fal fa-turn-down-left',
      title: 'Page.ReturnLists.Label',
      useStrictUserTypes: true,
      userRoles: [Constants._USER_ROLE_SHOP_RESPONSIBLE, Constants._USER_ROLE_USER],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Documents',
        icon: 'fal fa-hand-holding-box'
      }
    }, {
      route: ['form-return-lists/:id'],
      name: 'form-return-lists',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/documents/form-return-lists'),
      nav: false,
      icon: 'fal fa-turn-down-left',
      title: 'Page.ReturnLists.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR, Constants._USER_ROLE_SHOP_RESPONSIBLE, Constants._USER_ROLE_USER],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')
    }, {
      route: ['my-customer'],
      name: 'my-customer',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/my-customer'),
      nav: true,
      title: 'Page.MyCustomer.Label',
      useStrictUserTypes: true,
      additionalFilter: () => {
        return this.activeDestination?.enable_user_registration ? true : false
      },
      userRoles: [
        [Constants._USER_ROLE_SHOP_RESPONSIBLE, Constants._USER_ROLE_USER]
      ],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Settings',
        icon: 'fal fa-cog'
      }
    }/*, {
      route: ['labels'],
      name: 'labels',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/labels'),
      nav: true,
      title: 'Page.Labels.Label',
      useStrictUserTypes: true,
      userRoles: [
        [Constants._USER_ROLE_SHOP_RESPONSIBLE, Constants._USER_ROLE_USER]
      ],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      group: {
        title: 'Menu.Settings',
        icon: 'fal fa-cog'
      }
    }*/, {
      route: ['shop-drop-points'],
      name: 'shop-drop-points',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/drop-points'),
      nav: true,
      icon: 'fal fa-truck-ramp-box',
      title: 'Page.ShopDropPoints.Label',
      useStrictUserTypes: true,
      userRoles: [
        [Constants._USER_ROLE_SHOP_RESPONSIBLE, Constants._USER_ROLE_USER]
      ],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')/*,
      group: {
        title: 'Menu.Settings',
        icon: 'fal fa-cog'
      }*/
    }/*, {
      route: ['attestations'],
      name: 'attestations',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/attestations'),
      nav: true,
      icon: 'fal fa-download',
      title: 'Page.Attestations.Label',
      useStrictUserTypes: true,
      userRoles: [
        [Constants._USER_ROLE_SHOP_RESPONSIBLE, Constants._USER_ROLE_USER]
      ],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')
    }*/, {
      route: ['assistance'],
      name: 'assistance',
      href: 'assistance',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/shops/assistance'),
      nav: true,
      icon: 'fal fa-headphones',
      title: 'Page.Assistance.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')
    }, {
      route: ['utilities'],
      name: 'utilities',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/company-responsible/utilities'),
      nav: true,
      icon: 'fal fa-screwdriver-wrench',
      title: 'Page.Utilities.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')
    }, {
      route: ['form-user/:username?'],
      name: 'form-user',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/administrators/form-user'),
      nav: false,
      icon: 'fal fa-users',
      title: 'Page.FormUser.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')
    }, {
      route: ['users'],
      name: 'users',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/administrators/users'),
      nav: true,
      icon: 'fal fa-users',
      title: 'Page.Users.Label',
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR],
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')
    }, {
      route: ['signin'],
      name: 'signin',
      moduleId: PLATFORM.moduleName('lariolibri/views/users/signin'),
      nav: false,
      title: 'Page.SignIn.Label',
      disableAuth: true,
      layoutViewModel: PLATFORM.moduleName('lariolibri/layout/website')
    }, {
      route: ['signup'],
      name: 'signup',
      moduleId: PLATFORM.moduleName('lariolibri/views/users/signup'),
      nav: false,
      title: 'Page.SignUp.Label',
      disableAuth: true,
      layoutViewModel: PLATFORM.moduleName('lariolibri/layout/website')
    }, {
      route: ['signup-customer/:token?'],
      name: 'signup',
      moduleId: PLATFORM.moduleName('lariolibri/views/users/signup-customer'),
      nav: false,
      title: 'Page.SignUpCustomer.Label',
      disableAuth: true,
      layoutViewModel: PLATFORM.moduleName('lariolibri/layout/website')
    }, {
      route: ['privacy'],
      name: 'privacy',
      moduleId: PLATFORM.moduleName('lariolibri/views/users/privacy'),
      nav: false,
      title: 'Page.LibraryRegistrationPrivacy.Label',
      disableAuth: true,
      layoutViewModel: PLATFORM.moduleName('lariolibri/layout/website')
    }, {
      route: ['profile'],
      name: 'profile',
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/users/profile'),
      nav: false,
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri')
    }, {
      route: ['user-actions'],
      name: 'user-actions',
      nav: true,
      moduleId: PLATFORM.moduleName('@clevermind/tinty2-webapp-bootstrap/views/tracks/user-actions'),
      title: 'Page.UserActions.Label',
      icon: 'fal fa-history',
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      userRoles: [Constants._USER_ROLE_SUPERUSER, Constants._USER_ROLE_ADMINISTRATOR],
      group: {
        title: 'Generic.Log',
        icon: 'fal fa-history'
      }
    }, {
      route: ['order-tracks'],
      name: 'order-tracks',
      nav: true,
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/operators/order-tracks'),
      title: 'Page.OrderTracks.Label',
      icon: 'fal fa-history',
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      group: {
        title: 'Generic.Log',
        icon: 'fal fa-history'
      }
    }, {
      route: ['document-tracks'],
      name: 'document-tracks',
      nav: true,
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/operators/documents-tracks'),
      title: 'Page.DocumentTracks.Label',
      icon: 'fal fa-history',
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      group: {
        title: 'Generic.Log',
        icon: 'fal fa-history'
      }
    }, {
      route: ['notifications-sent'],
      name: 'notifications-sent',
      nav: true,
      moduleId: PLATFORM.moduleName('@clevermind/tinty2-webapp-bootstrap/views/tracks/notifications-sent'),
      title: 'Page.NotificationsSent.Label',
      icon: 'fal fa-history',
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      group: {
        title: 'Generic.Log',
        icon: 'fal fa-history'
      }
    }, {
      route: ['webhook-tracks'],
      name: 'webhook-tracks',
      nav: true,
      moduleId: PLATFORM.moduleName('@clevermind/tinty2-webapp-bootstrap/views/tracks/webhook-tracks'),
      title: 'Page.WebhookTracks.Label',
      icon: 'fal fa-history',
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR],
      group: {
        title: 'Generic.Log',
        icon: 'fal fa-history'
      }
    }, {
      route: ['form-webhook-tracks/:id'],
      name: 'form-webhook-tracks',
      nav: false,
      moduleId: PLATFORM.moduleName('@clevermind/tinty2-webapp-bootstrap/views/tracks/form-webhook-tracks'),
      title: 'Page.FormWebhookTracks.Label',
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR]
    }, {
      route: ['os1-suborders'],
      name: 'os1-suborders',
      nav: true,
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/operators/os1-suborders'),
      title: 'Page.OS1Suborders.Label',
      icon: 'fal fa-history',
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      group: {
        title: 'Generic.Log',
        icon: 'fal fa-history'
      }
    }, {
      route: ['os1-orders-details'],
      name: 'os1-orders-details',
      nav: true,
      moduleId: PLATFORM.moduleName('@clevermind/webapp-lariolibri/views/operators/os1-orders-details'),
      title: 'Page.OS1OrdersDetails.Label',
      icon: 'fal fa-history',
      layoutViewModel: PLATFORM.moduleName('@clevermind/webapp-lariolibri/layout/lariolibri'),
      userRoles: [Constants._USER_ROLE_ADMINISTRATOR, Constants._USER_ROLE_COMPANY_RESPONSIBLE, Constants._USER_ROLE_COMPANY_OPERATOR],
      group: {
        title: 'Generic.Log',
        icon: 'fal fa-history'
      }
    }]);
  }
}

// Needed to be correctly imported by extending apps
export class LarioLibriStep extends AuthorizeStep {
  async run(navigationInstruction, next) {
    const _webapp = window.webapp;
    if(!_webapp.user) {
      // Tyring to access our profile data
      try {_webapp.user = await _webapp.getMe();} catch(e) {}
    }
    // Checking if we are authenticated (if not and if possible, will also try to refresh the tokens to ensure we are really not authenticated)
    if (await _webapp.auth.isAuthenticated()) {
      // ensure to set the user after the refresh
      if (!_webapp.user) {
        _webapp.user = await _webapp.getMe();
      }
    }
    const isAuthenticated = await _webapp.auth.isAuthenticated();
    const isHomepage = navigationInstruction.fragment === '' || navigationInstruction.fragment === '/' || navigationInstruction.fragment === '/reserved-area';
    if (isAuthenticated && _webapp.user && _webapp.user.isDropPointResponsible() && isHomepage) {
      return next.cancel(new Redirect('drop-point-receiving'));
    }
    if (isAuthenticated && _webapp.user && _webapp.user.isCompanyWarehouseOperator() && isHomepage) {
      return next.cancel(new Redirect('warehouse-orders'));
    }
    if (isAuthenticated && _webapp.user && (_webapp.user.isGDOAdministrator() || _webapp.user.isGDOResponsible()) && isHomepage) {
      _webapp.debug( '[Login Check] %o with roles %o is not authorized to access to Lario Libri %s...', _webapp.user.username, _webapp.user.roles, navigationInstruction.config.route );
      _webapp.signOutOnUnAuthorizedRouteAccess = true;
      // Redirecting to login ( the login will return the user to the previous wanted view )
      return next.cancel(new Redirect('signin'));
    }
    return super.run(navigationInstruction, next);
  }
}